.homeContentContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    width: 100%;
    flex: 1;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #888888;
}

.homeContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 85%;
    flex: 1;
    /* margin-top: 1.5rem; */
    margin-bottom: 1rem;
}

.avatar {
    display: flex;
    align-self: flex-end;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
}

.profileInfo {
    background: #f2f3f5;
    border-radius: 10px;
    margin-top: .75rem;
    margin-bottom: .75rem;
    display: flex;
    flex-direction: column;
    width: 85%;
    align-self: center;
}

.profileInfo h4 {
    align-self: flex-start;
    margin-left: 1.5rem;
    margin-top: unset;
    margin-bottom: unset;
}

.profileInfo p {
    align-self: flex-end;
    margin-right: 1.5rem;
}

.infoBar {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
}