.bredkrumPageContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 85%;
    flex: 1;
    /* margin-top: 1.5rem; */
    margin-bottom: 1rem;
}
