.cardContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.phoneInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    /* width: 80%; */
}

