.headerLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 1;
}

.headerLogo img {
    max-width: 350px;
    color: white;
    margin-top: 2rem;
    margin-bottom: .5rem;
}
