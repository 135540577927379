.founditContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    width: 85%;
    flex: 1;
    margin-top: .5rem;
    margin-bottom: 1rem;
}
