.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	box-sizing: border-box;
}

.siteContent {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin-top: 9rem;
	margin-bottom: 3.5rem;
}

@media (min-width: 451px) {
	.siteContent {
		width: 30rem;
		align-self: center;
	}
}

.header {
	flex: 1;
}

.footer {
	position: fixed;
	bottom: 0;
	width: 100%;
}
