.jumbotron {
    border-radius: 10px;
    box-shadow: 2px 2px 10px #888888;
    background: #EAECEF;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.title {
    margin-right: .75rem;
    align-self: flex-end;
}

.message {
    align-self: center;
    border-radius: 8px;
    box-shadow: 1px 1px 10px #888888;
    padding: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    background: #fafafb;
    margin-bottom: .75rem;
    width: 85%;
    align-items: center;
}