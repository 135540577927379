.jumbotron {
    border-radius: 10px;
    box-shadow: 2px 2px 10px #888888;
    background: #EAECEF;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 1rem;
}

.title {
    margin-left: .75rem;
    align-self: flex-start;
}

.finderMessageContainer {
    align-self: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background: #fafafb;
    box-shadow: 1px 1px 10px #888888;
    margin-bottom: .75rem;
    width: 85%;
    align-items: center;
}

.textFields {
    display: flex;
    flex-direction: column;
    width: 96%;
    flex: 1;
    margin-bottom: .5rem;
}

.additionalInformation {
    margin-top: .5rem;
}




.jumbotronContainer {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    max-width: 85%;
    flex: 1;
}