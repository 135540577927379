
.bredkrumRenderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: .5rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #888888;
}

.rowOne {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    align-items: baseline;
}

.infoFields {
    align-self: center;
    width: 85%;
    display: flex;
    flex-direction: column;
}

.infoMargin {
    margin-bottom: 1.35rem;
}



